



export default function MissionCard({
  index,
  mission,
}) {


  return (
      <>
      <div className="flex flex-col gap-3 bg-[#1C1E2A] pl-4 pr-4 pb-4 pt-2">
        <div className="font-bold">{mission.name}</div>
        <div>
          <div  className="flex justify-center">
        <div className="w-full border-t border border-1 border-stone-300 absolute mt-1 z-0"></div>
            </div>
        <div className="flex justify-between z-1">
        {mission.actions.map(action => {
          return (<div className="flex flex-col justify-center items-center"><div className="bg-white rounded-full w-[10px] h-[10px]"></div><div className="text-sm">{action.locationName}</div></div>)
        })}
          </div>
        </div>
      </div>
      </>
  );
}
