import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useCallback } from "react";
import axios from "axios";
import { useRef } from "react";


import "react-phone-input-2/lib/bootstrap.css";

import { fleetListAndRobotStatusByUserEmail } from "../redux/actions/fleet";
import { useLocation } from "react-router-dom";

import { storeRobotDetails } from "../redux/Actions";
import RobotCard from "./RobotCard";
import MissionCard from "./MisisonCard";
import FleetPagerobotPage from "../components/FleetPagerobotPage";
function Missions(props) {



  const [robotData, setRobotData] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState();

  const audioRef = useRef(null);
  const dispatch = useDispatch();
  const incomingData = useLocation();
  const emailId = localStorage.getItem("useremail");
  const { user, sessionDestroyed } = useSelector((state) => state.login);
  const [missionData, setMissionData] = useState([])
  const fleetData = JSON.parse(localStorage.getItem("fleetData"));



  const updateState = useCallback(async () => {
    if (!sessionDestroyed && token) {
      dispatch(fleetListAndRobotStatusByUserEmail(emailId));
    }
  }, [dispatch, emailId, sessionDestroyed]);

  const useFleetIntervalAsync = (callbackFunction, intervalInMs) => {
    const timeout = useRef();

    const apiCall = useCallback(async () => {
      await callbackFunction();
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
      timeout.current = window.setTimeout(apiCall, intervalInMs);
    }, [callbackFunction, intervalInMs]);

    useEffect(() => {
      apiCall();
      return () => {
        clearTimeout(timeout.current);
      };
    }, [apiCall]);
  };


  const token = localStorage.getItem("token");



  useEffect(() => {
    const fleetId = props.fleetData ? props.fleetData._id : fleetData._id;
    dispatch(storeRobotDetails(fleetId));
  }, []);




  function parseItem(item) {
    const [, stringPart = "", numberPart = 0] =
      /(^[a-zA-Z]*)(\d*)$/.exec(item) || [];
    return [stringPart, numberPart];
  }
  const updateRobotETA = useCallback(async () => {
    if (!sessionDestroyed && token) {
      return await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}getRobotsETA/${
            props.fleetData ? props.fleetData._id : fleetData._id
          }`
        )
        .then((res) => {
          setRobotData(
            res.data.result.sort((a, b) => {
              const [stringA, numberA] = parseItem(a.robotId);
              const [stringB, numberB] = parseItem(b.robotId);
              const comparison = stringA.localeCompare(stringB);
              return comparison === 0
                ? Number(numberA) - Number(numberB)
                : comparison;
            })
          );
        })
        .catch((err) => {
          console.error(err);
          toast.error(err.message);
          // toast.error("Something went wrong")
        });
      // console.log("Result",res.data.result.sort((a,b) => a.robotId - b.robotId))
    }
  }, [fleetData._id, props.fleetData, sessionDestroyed]);
  const getMissions = useCallback(async () => {
    if (!sessionDestroyed && token) {
      return await axios
        .post(
          `${process.env.REACT_APP_BASE_URL}missions/getmissionbyfleetid`,{"fleetId":fleetData._id},{
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          setMissionData(
            res.data.missions)
        })
        .catch((err) => {
          console.error(err);
          toast.error(err.message);
          // toast.error("Something went wrong")
        });
      // console.log("Result",res.data.result.sort((a,b) => a.robotId - b.robotId))
    }
  }, [fleetData._id, props.fleetData, sessionDestroyed]);
  const playSound = async () => {
    if (audioRef.current) {
      try {
        await audioRef.current.play();
      } catch (error) {
        console.error("Failed to play audio:", error);
      }
    }
    // audioRef.current.play();
  };

  const stopSound = async () => {
    if (audioRef.current) {
      try {
        await audioRef.current.pause();
      } catch (error) {
        console.error("Failed to pause audio:", error);
      }
    }
    // audioRef.current.pause();
  };


  useEffect(() => {
    // console.log(incomingData, "incomingData")
    if (
      incomingData &&
      incomingData?.state?.userData &&
      user &&
      Object.keys(user).length > 0
    ) {
      toast.success("Logged in Successfully!");
    }
  }, [user, incomingData]);




  const useIntervalAsync = (callbackFunction, intervalInMs) => {
    const timeout = useRef();
    const apiCall = useCallback(async () => {
      await callbackFunction();
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
      timeout.current = window.setTimeout(apiCall, intervalInMs);
    }, [callbackFunction, intervalInMs]);

    useEffect(() => {
      apiCall();
      return () => {
        clearTimeout(timeout.current);
      };
    }, [apiCall]);
  };

  useFleetIntervalAsync(updateState, 5000);
  useIntervalAsync(updateRobotETA, 1000 * 2);
  useIntervalAsync(getMissions,1000 * 2);
  return (
    <>
      <div className="grid grid-cols-8 gap-3 h-full">
        <audio ref={audioRef}>
          <source src="/assets/audio/alert.wav" type="audio/wav"></source>
        </audio>
        <div className="grid col-span-3 text-white bg-[#282F42] p-3 rounded-lg ">
          <div className="relative flex flex-col gap-2 min-h-[200px] max-h-[200px] overflow-auto">
            <span className="sticky top-0 bg-[#282f41] pb-2 flex items-center text-lg font-semibold font-poppins">
              Robots
            </span>

            {robotData && robotData?.length !== 0 ? (
              <>
                {robotData.map((robot, index) => (
                  <RobotCard
                    key={index}
                    index={index}
                    robot={robot}
                    selectedLocation={selectedLocation}
                    showSummonControls={false}
                    playSound={playSound}
                    stopSound={stopSound}
                  />
                ))}
              </>
            ) : (
              <div className="text-[rgb(255,255,255,0.7)] font-semibold flex items-center justify-center w-full h-full ">
                {" "}
                No Robot to show
              </div>
            )}
          </div>
        </div>
        <div className="grid col-span-5 text-white bg-[#282F42] p-3 rounded-lg ">
          <div className="relative flex flex-col gap-2 min-h-[200px] max-h-full overflow-auto">
            <span className="sticky top-0 bg-[#282f41] pb-2 flex items-center text-lg font-semibold font-poppins">
              Active Missions
            </span>

            {missionData && missionData?.length !== 0 ? (
              <>
                {missionData.map((mission, index) => (
                  <MissionCard
                    key={index}
                    index={index}
                    mission={mission}
                  />
                ))}
              </>
            ) : (
              <div className="text-[rgb(255,255,255,0.7)] font-semibold flex items-center justify-center w-full h-full ">
                {" "}
                No Missions to show
              </div>
            )}
          </div>
        </div>
        <div className="grid col-span-8 text-white bg-[#282F42] p-3 rounded-lg h-full ">
        <FleetPagerobotPage
          googlemap={true}
          fleetData={props.fleetData ? props.fleetData : fleetData}
        />       </div>
      </div>
    </>
  );
}

export default Missions;
