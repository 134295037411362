import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';


function MapboxLocation(props) {
  console.log(props.showMarker, "marker")
//   mapboxgl.accessToken =
//     "pk.eyJ1IjoibXJsYWtzaHlhZ3VwdGEiLCJhIjoiY2xpZWZlcTJjMGo4ZDNkczBwc2ZmcW1qMyJ9.Kurk7BS04invJcCwS0m4Pg";

// const mapRef = useRef();
const mapContainerRef = useRef(null);
  const [markerArray, setmarkerArray] = useState([]);
  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(15);
  const [marker, setMarker] = useState(null);

  const [geofencecoordinates, setgeofencecoordinates] = useState(
    props?.fleetData?.map?.geofence?.map((data) => [data.lng, data.lat])
  );

  const isLatLongValid = () => {
    const isValidLatitude = props.latitude && !isNaN(props.latitude) && props.latitude >= -90 && props.latitude <= 90;
    const isValidLongitude = props.longitude &&!isNaN( props.longitude ) &&  props.longitude  >= -180 &&  props.longitude  <= 180;
    const isValidHeading = props.headingAngle && !isNaN(props.headingAngle) && props.headingAngle >= -360 && props.headingAngle <= 360;
    
    return isValidLatitude && isValidLongitude && isValidHeading;
  }
  
  const defaultCoord = (props?.fleetData?.map?.geofence && props?.fleetData?.map?.geofence.length>0 )?[props?.fleetData?.map?.geofence[0].lng, props?.fleetData?.map?.geofence[0].lat]:[28,77];

  if(!isLatLongValid() && marker && map){
    // map.removeMarker(marker);
    map.setCenter(defaultCoord)
    marker.remove()
  }

  // console.log(props.headingAngle, 'heading')
  useEffect(() => {

    // const createMarker = (map,latitude, longitude, headingAngle)=> {
    //     if(marker){
    //         const lngLat = [parseFloat(longitude), parseFloat(latitude)];
    //         marker.setLngLat(lngLat).addTo(map);
    //         return ;
    //     }

    //     const newMarker =new mapboxgl.Marker()
    //     .setLngLat([longitude, latitude])
    //     .addTo(map);

    //     return newMarker;
    // }
    const initializeMap = () => {
      const newMap = new mapboxgl.Map({
        // container: "map-container-id",
        container: mapContainerRef.current,
        style: "mapbox://styles/mrlakshyagupta/climr57of00le01pgc0jjgfoh",
        // center: [isLatLongValid()?props.longitude:28,isLatLongValid()?props.latitude:77],
        center: [isLatLongValid()?props.longitude:defaultCoord[0],isLatLongValid()?props.latitude:defaultCoord[1]],
        // bearing: props.headingAngle,
        zoom: 19,
      });


      // newMap.setCenter([props.longitude,props.latitude]);

      newMap.flyTo({
        center: [props.longitude,props.latitude],
        bearing: props.headingAngle,
        zoom: 15
      });

      // newMap.on("load", () => {
      //   updateBearing();
      // })


      newMap &&
      geofencecoordinates &&
      geofencecoordinates.length > 0 &&
      newMap.on("load", () => {
        newMap.addSource("geofence", {
          type: "geojson",
          data: {
            type: "Feature",
            geometry: {
              type: "Polygon",
              coordinates: [geofencecoordinates],
            },
          },
        });
        newMap.addLayer({
          id: "geofenceoutline",
          type: "line",
          source: "geofence",
          layout: {},
          paint: {
            "line-color": "#f00",
            "line-width": 3,
          },
        });
      //   const padding = { left: 300, right: 100, top: 100, bottom: 30 }; //<--- padding
        // newMap.setMaxBounds([geofencecoordinates[0]+10, geofencecoordinates[geofencecoordinates.length-1]+10])

      //   newMap.fitBounds(geofencecoordinates, {
      //     padding, //<--- used here
      //     linear: true,
      //     duration: 0
      // });

      // newMap.showPadding = true
      newMap.setZoom(15)
      });


      if(props?.fleetData?.map?.teleoperationZones && props?.fleetData?.map?.teleoperationZones?.length > 0){
        let polygonArray = [];
        props?.fleetData?.map?.teleoperationZones.map((polygon, index) => {
          polygonArray.push(polygon.map((data) => [data.lng, data.lat]));

          newMap.on("load", () => {
            newMap.addSource("fleetzone-" + index, {
              type: "geojson",
              data: {
                type: "Feature",
                geometry: {
                  type: "Polygon",

                  coordinates: [polygonArray[index]],
                },
              },
            });

            newMap.addLayer({
              id: "fleetzoneoutline-" + index,
              type: "fill",
              source: "fleetzone-" + index,
              layout: {},
              paint: {
                "fill-color": "#7859bc",
                "fill-opacity": 0.75,
              },
            });
          });

          return polygonArray;
        });
      
      }
      

      // if (props?.fleetData?.map?.geofencezone?.length > 0) {
      //   setgeofencecoordinates(
      //     props?.geofencezone?.map((data) => [data.lng, data.lat])
      //   );
      // }

      // const markerElement = document.createElement('div');
      // markerElement.className = 'marker';
      // markerElement.style.transform = `rotate(${props.headingAngle}deg)`;

      if(props.showMarker){
        const robotMarkerDiv = document.createElement("div");
        robotMarkerDiv.style.width = "35px";
        robotMarkerDiv.style.height = "35px";
        robotMarkerDiv.style.backgroundImage = "url(/assets/images/robot-icon-2.svg)";
        robotMarkerDiv.style.borderRadius = "100%";
        robotMarkerDiv.style.animation = "example 1s ease-in-out infinite";
        robotMarkerDiv.style.transform = `rotate(${props.headingAngle}deg)`
    // robotMarkerDiv.style.border = "3px solid #fff";
      
        const newMarker =new mapboxgl.Marker(robotMarkerDiv)
        .setLngLat([props.longitude, props.latitude])
        .addTo(newMap);

        setMap(newMap);
        setMarker(newMarker);
      }
    // const newMarker = createMarker(map, props.latitude, props.longitude, props.headingAngle)

      // mapRef.current = newMap;
    };

    if (!map ) {
        initializeMap();
    }

    function updateBearing() {
      if (marker) {
        map.easeTo({
          bearing: props.headingAngle,
          pitch: 60,
          duration: 100,
          essential: true
        });
      }
    }

    if (map && props.showMarker) {
      console.log("show marker hit")
        // initializeMap()
        const lngLat = [parseFloat(props.longitude), parseFloat(props.latitude)];
        const angle = parseFloat(props.headingAngle);
        map.flyTo({ center: lngLat,zoom:15 });
  
        // Create a new marker
        // const markerElement = document.createElement('div');
        // markerElement.className = 'marker';
        // markerElement.style.transform = `rotate(${angle}deg)`;

        marker.setLngLat(lngLat).setRotation(
          parseFloat(
            props.headingAngle
              ? props.headingAngle
              : 0
          )).addTo(map)
        // updateBearing()
      }

      if (map && geofencecoordinates.length > 0) {
        map.setZoom(14);
        const bounds = new mapboxgl.LngLatBounds(
          geofencecoordinates[0],
          geofencecoordinates[0]
        );

        for (const coord of geofencecoordinates) {
          bounds.extend(coord);
        }

          const padding = { left: 70, right: 70, top: 100, bottom: 50 }; //<--- padding
        map?.fitBounds(bounds, {
          padding
        });

      }
      
  }, [map, setMap, props.latitude, props.longitude, props.headingAngle]);

  if(map && isLatLongValid() && map.getCenter().lat!==parseFloat(props.latitude) && map.getCenter().lng!==parseFloat(props.longitude)){
    map.flyTo({center: [props.longitude,props.latitude]})
  }


  return (
    <div style={{ height: "50vh" }}>
      <div
        id="map-container-id"
        ref={mapContainerRef}
        className="map-container"
        style={{ height: "100%" }}
      ></div>
    </div>
  );
}

export default MapboxLocation;
