import React, { Fragment, useState } from "react";
import { IoCloseSharp, IoEyeOutline } from "react-icons/io5";
import { useSelector } from "react-redux";

const CustomAccordion = ({
  order,
  orderStatusColor,
  orderIndex,
  chartOrderData,
  customFormatTime,
  fleetData,
  locationPositionValuePriority,
  hasTrip,
  count,
}) => {
  // const [distanceUnit, setDistanceUnit] = useState(
  //   fleetData?.booking?.analyticsConfig?.defaultDistanceUnit || "KM"
  // );
  const distanceUnit = useSelector((state) => state?.analyticsReducer?.distanceUnit)

  const [isActive, setIsActive] = useState(false);
  const [timeZone, setOrderTimeZone] = useState("America/New_York");
  const customDistanceFormat = (distance) => {
    if (distance) {
      switch (distanceUnit) {
        case "miles":
          return (distance * 0.00062137).toFixed(2);
        case "KM":
          return (distance * 0.001).toFixed(2);
        default:
          return (distance * 0.001).toFixed(2);
      }
    } else {
      return 0;
    }
  };

  const renderRows = () => {
    const arr = [];
    for (let i = orderIndex; i < orderIndex + count; i++) {
      arr.push(
        <>
          <tr
            className={`${isActive && "bg-[#4B505F]"} ${
              i !== chartOrderData.length - 1 &&
              !isActive &&
              "border-b border-[rgba(255,255,255,0.16)]"
            }`}
          >
            { 
              !chartOrderData[i]?.tripId?.tripId ? (hasTrip?<td></td>:<></>):
               chartOrderData[i]?.tripId?.tripId &&
            chartOrderData[i + 1]?.tripId?.tripId ===
              chartOrderData[i]?.tripId?.tripId &&
            (i === 0 ||
              chartOrderData[i - 1]?.tripId?.tripId !==
                chartOrderData[i]?.tripId?.tripId) ? (
              <td
                className={`p-3  relative z-[10] `}
                rowSpan={
                  i === orderIndex ? (isActive ? count * 2 + 1 : count) : 0
                }
              >
                <td className="z-[10]">{chartOrderData[i]?.tripId?.tripId}</td>
              </td>
            ) : chartOrderData[i - 1]?.tripId?.tripId ===
              chartOrderData[i]?.tripId?.tripId ? (
              <td className="p-3 hidden">
                {chartOrderData[i]?.tripId?.tripId}
              </td>
            ) : 
            (
              <td
                className={`p-3 ${isActive && "align-top"}	`}
                rowSpan={
                  isActive
                    ? chartOrderData[i].hasOwnProperty("tripId") &&
                      chartOrderData[i]?.tripId?.hasOwnProperty("commands") &&
                      chartOrderData[i]?.tripId?.commands?.length > 0 &&
                      chartOrderData[i]?.tripId?.commands?.find(
                        (command) => command.message === "MOVESUMMON"
                      )
                      ? 3
                      : 2
                    : 1
                }
              >
                {chartOrderData[i]?.tripId?.tripId}
              </td>
            )
            }
            {/* <td className="p-3 rounded-tl-md	">{chartOrderData[i]?.tripId?.tripId}</td> */}
            <td className="p-3 ">
              {fleetData?.robotsDetails?.find(
                (robot) => robot.robotId === chartOrderData[i]?.robotId
              )?.displayRobotName
                ? fleetData?.robotsDetails?.find(
                    (robot) => robot.robotId === chartOrderData[i]?.robotId
                  )?.displayRobotName
                : chartOrderData[i]?.robotId}
            </td>
            <td className="p-3">{chartOrderData[i]?.orderId}</td>
            {chartOrderData[i]?.hasOwnProperty("orderInfo") &&
            chartOrderData[i]?.orderInfo.hasOwnProperty("analyticsdata") &&
            chartOrderData[i]?.orderInfo.analyticsdata.hasOwnProperty(
              "numberoforder"
            ) ? (
              <td className="p-4 align-top">
                <div className="flex flex-col items-start gap-2 justify-evenly">
                  <div className="flex gap-1">
                    <span className="font-bold text-sm text-[rgb(255,255,255,0.7)]">
                      Total Orders :
                    </span>
                    <span className="text-sm text-[#02B7D4] font-bold">
                      {chartOrderData[i]?.hasOwnProperty("orderInfo") &&
                      chartOrderData[i]?.orderInfo.hasOwnProperty(
                        "analyticsdata"
                      ) &&
                      chartOrderData[i]?.orderInfo.analyticsdata.hasOwnProperty(
                        "numberoforder"
                      )
                        ? chartOrderData[i]?.orderInfo.analyticsdata
                            .numberoforder
                        : "0"}{" "}
                    </span>
                  </div>
                  <div className="flex gap-1">
                    <span className="font-bold text-sm text-[rgb(255,255,255,0.7)]">
                      Total Bottles :
                    </span>
                    <span className="text-sm text-[#02B7D4] font-bold">
                      {chartOrderData[i]?.hasOwnProperty("orderInfo") &&
                      chartOrderData[i]?.orderInfo.hasOwnProperty(
                        "analyticsdata"
                      ) &&
                      chartOrderData[i]?.orderInfo.analyticsdata.hasOwnProperty(
                        "numberofbottles"
                      )
                        ? chartOrderData[i]?.orderInfo.analyticsdata
                            .numberofbottles
                        : "0"}{" "}
                    </span>
                  </div>
                </div>
              </td>
            ) : (
              <td></td>
            )}
            <td className="p-3 font-semibold capitalize">
              {orderStatusColor(
                chartOrderData[i]?.orderStatus,
                chartOrderData[i]?.statusCode
              )}
            </td>
            <td className="p-3">{chartOrderData[i]?.cabinId}</td>
            {fleetData?.booking?.pickup &&
              fleetData?.booking?.pickup.length !== 0 && (
                <td className="p-3">
                  {locationPositionValuePriority(chartOrderData[i]?.pickup)}
                </td>
              )}
            {fleetData?.booking?.delivery &&
              fleetData?.booking?.delivery.length !== 0 && (
                <td className="p-3">
                  {locationPositionValuePriority(chartOrderData[i]?.delivery)}
                </td>
              )}
            {/* <td className="p-3"></td> */}
            <td className="p-3">
              {new Date(chartOrderData[i]?.createdAt).toLocaleString("en-US", {
                timeZone: chartOrderData[i]?.timeZone ?? "America/New_York",
                timeZoneName: "short",
              })}{" "}
            </td>
            {!chartOrderData[i]?.tripId?.tripId ? (
              !isActive ? (
                <td align="center" className="p-3" rowSpan={1}>
                  <IoEyeOutline
                    className="w-5 h-5 cursor-pointer text-[#00B7D4]"
                    onClick={() => setIsActive(true)}
                  />
                </td>
              ) : (
                <td align="center" className="p-3">
                  <IoCloseSharp
                    className="w-5 h-5 cursor-pointer text-[#E96C67]"
                    onClick={() => setIsActive(false)}
                  />
                </td>
              )
            ) : chartOrderData[i + 1]?.tripId?.tripId ===
                chartOrderData[i]?.tripId?.tripId &&
              (i === 0 ||
                chartOrderData[i - 1]?.tripId?.tripId !==
                  chartOrderData[i]?.tripId?.tripId) ? (
              !isActive ? (
                <td
                  align="center"
                  className="p-3"
                  rowSpan={
                    i === orderIndex ? (isActive ? count * 3 : count) : 0
                  }
                >
                  <IoEyeOutline
                    className="w-5 h-5 cursor-pointer text-[#00B7D4]"
                    onClick={() => setIsActive(true)}
                  />
                </td>
              ) : (
                <td align="center" className="p-3">
                  <IoCloseSharp
                    className="w-5 h-5 cursor-pointer text-[#E96C67]"
                    onClick={() => setIsActive(false)}
                  />
                </td>
              )
            ) : chartOrderData[i - 1]?.tripId?.tripId ===
              chartOrderData[i]?.tripId?.tripId ? (
              !isActive ? (
                <td align="center" className="p-3 hidden">
                  <IoEyeOutline
                    className="w-5 h-5 cursor-pointer text-[#00B7D4]"
                    onClick={() => setIsActive(true)}
                  />
                </td>
              ) : (
                <td align="center" className="p-3">
                  <IoCloseSharp
                    className="w-5 h-5 cursor-pointer text-[#E96C67] hidden"
                    onClick={() => setIsActive(false)}
                  />
                </td>
              )
            ) : !isActive ? (
              <td align="center" className="p-3">
                <IoEyeOutline
                  className="w-5 h-5 cursor-pointer text-[#00B7D4]"
                  onClick={() => setIsActive(true)}
                />
              </td>
            ) : (
              <td align="center" className="p-3">
                <IoCloseSharp
                  className="w-5 h-5 cursor-pointer text-[#E96C67]"
                  onClick={() => setIsActive(false)}
                />
              </td>
            )}
            {/* {!isActive ? (
                <IoEyeOutline
                  className="w-5 h-5 cursor-pointer text-[#00B7D4]"
                  onClick={() => setIsActive(true)}
                />
              ) : (
                <IoCloseSharp
                  className="w-5 h-5 cursor-pointer text-[#E96C67]"
                  onClick={() => setIsActive(false)}
                />
              )} */}
          </tr>
          {isActive && (
            <>
              <tr className="relative shadow-[inset_0px_0px_0px_12px_#4B505F]">
                <td className="relative p-4 align-top">
                  <div className="flex flex-col">
                    <span className="font-bold text-sm text-[rgb(255,255,255,0.7)]">
                      Total Time
                    </span>
                    <span className="text-xl text-[#02B7D4] font-bold">
                      {chartOrderData[i]?.hasOwnProperty("orderTime")
                        ? customFormatTime(chartOrderData[i]?.orderTime) !== ""
                          ? customFormatTime(chartOrderData[i]?.orderTime)
                          : "0 sec"
                        : "0 sec"}{" "}
                    </span>
                  </div>
                </td>
                <td
                  colSpan={chartOrderData[i]?.ETA ? 4 : 5}
                  className="p-4 border-x border-x-[#282f42] align-top"
                >
                  <div className="flex items-start gap-2 justify-evenly">
                    {chartOrderData[i]?.pickupTransitTime ? (
                      <div className="flex flex-col">
                        <span className="font-bold text-sm text-[rgb(255,255,255,0.7)]">
                          Pickup Time
                        </span>
                        <span className="text-xl text-[#02B7D4] font-bold">
                          {chartOrderData[i]?.hasOwnProperty(
                            "pickupTransitTime"
                          )
                            ? customFormatTime(
                                chartOrderData[i]?.pickupTransitTime
                              ) !== ""
                              ? customFormatTime(
                                  chartOrderData[i]?.pickupTransitTime
                                )
                              : "0 sec"
                            : "0 sec"}{" "}
                        </span>
                      </div>
                    ) : null}
                    {chartOrderData[i]?.pickupWaitTime ? (
                      <div className="flex flex-col">
                        <span className="font-bold text-sm text-[rgb(255,255,255,0.7)]">
                          Pickup Wait Time
                        </span>
                        <span className="text-xl text-[#02B7D4] font-bold">
                          {chartOrderData[i]?.hasOwnProperty("pickupWaitTime")
                            ? customFormatTime(
                                chartOrderData[i]?.pickupWaitTime
                              ) !== ""
                              ? customFormatTime(
                                  chartOrderData[i]?.pickupWaitTime
                                )
                              : "0 sec"
                            : "0 sec"}{" "}
                        </span>
                      </div>
                    ) : null}
                    <div className="flex flex-col">
                      <span className="font-bold text-sm text-[rgb(255,255,255,0.7)]">
                        Delivery Time
                      </span>
                      <span className="text-xl text-[#02B7D4] font-bold">
                        {chartOrderData[i]?.hasOwnProperty("dropoffTransitTime")
                          ? customFormatTime(
                              chartOrderData[i]?.dropoffTransitTime
                            ) !== ""
                            ? customFormatTime(
                                chartOrderData[i]?.dropoffTransitTime
                              )
                            : "0 sec"
                          : "0 sec"}{" "}
                      </span>
                    </div>
                    <div className="flex flex-col">
                      <span className="font-bold text-sm text-[rgb(255,255,255,0.7)]">
                        Delivery Wait Time
                      </span>
                      <span className="text-xl text-[#02B7D4] font-bold">
                        {chartOrderData[i]?.hasOwnProperty("dropoffWaitTime")
                          ? customFormatTime(
                              chartOrderData[i]?.dropoffWaitTime
                            ) !== ""
                            ? customFormatTime(
                                chartOrderData[i]?.dropoffWaitTime
                              )
                            : "0 sec"
                          : "0 sec"}{" "}
                      </span>
                    </div>
                  </div>
                </td>
                {chartOrderData[i]?.ETA ? (
                  <td className="p-4 border-r border-r-[#282f42] ">
                    <div className="flex flex-col">
                      <span className="font-bold text-sm text-[rgb(255,255,255,0.7)]">
                        Estimated Delivery Time
                      </span>
                      <div className="flex items-center gap-1">
                        {/* <span className="h-[6px] w-[18px] bg-[#E96C67] rounded-full"></span>{" "} */}
                        <span
                          className={`text-xl ${
                            chartOrderData[i]?.ETA >
                            chartOrderData[i]?.orderTime
                              ? "text-[#71cf62]"
                              : "text-[#E96C67]"
                          } font-bold`}
                        >
                          {chartOrderData[i]?.hasOwnProperty("ETA")
                            ? customFormatTime(chartOrderData[i]?.ETA) !== ""
                              ? customFormatTime(chartOrderData[i]?.ETA)
                              : "0 sec"
                            : "0 sec"}{" "}
                        </span>
                      </div>
                    </div>
                  </td>
                ) : null}
                <td className="relative p-4 ">
                  <div className="flex flex-col">
                    <span className="font-bold text-sm text-[rgb(255,255,255,0.7)]">
                      Total Distance
                    </span>
                    <span className="text-xl text-[#02B7D4] font-bold">
                      {customDistanceFormat(chartOrderData[i].orderDistance)}
                      {" " + distanceUnit}
                    </span>
                  </div>
                </td>
              </tr>

              {i === orderIndex + count - 1 &&
                chartOrderData[i]?.hasOwnProperty("tripId") &&
                chartOrderData[i]?.tripId?.hasOwnProperty("commands") &&
                chartOrderData[i]?.tripId?.commands?.length > 0 &&
                chartOrderData[i]?.tripId?.commands?.find(
                  (command) => command.message === "MOVESUMMON"
                ) && (
                  <tr
                    className={`relative bg-[#282f42] shadow-[inset_0px_0px_0px_12px_#4B505F]`}
                  >
                    <td colSpan={7} className="p-4  align-top">
                      <div className="flex items-start gap-2 justify-between">
                        <div className="flex flex-col">
                          <span className="font-bold text-sm text-[rgb(255,255,255,0.7)]">
                            Summon Location
                          </span>
                          <span className="text-xl text-[#02B7D4] font-bold">
                            {
                              chartOrderData[i]?.tripId?.commands[0]
                                ?.locationName
                            }
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="font-bold text-sm text-[rgb(255,255,255,0.7)]">
                            Summon Distance
                          </span>
                          <span className="text-xl text-[#02B7D4] font-bold">
                            {customDistanceFormat(
                              chartOrderData[i]?.tripId?.commands[0]
                                ?.actionDistance
                            ) +
                              " " +
                              distanceUnit}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="font-bold text-sm text-[rgb(255,255,255,0.7)]">
                            Summon Time
                          </span>
                          <span className="text-xl text-[#02B7D4] font-bold">
                            {customFormatTime(
                              (new Date(
                                chartOrderData[i]?.tripId?.commands.filter(
                                  (item) => item?.message === "MOVESUMMON"
                                )[0]?.finishedAt
                              ).getTime() -
                                new Date(
                                  chartOrderData[i]?.tripId?.commands.filter(
                                    (item) => item?.message === "MOVESUMMON"
                                  )[0].startedAt
                                ).getTime()) /
                                1000
                            )}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="font-bold text-sm text-[rgb(255,255,255,0.7)]">
                            Number of Shipments
                          </span>
                          <span className="text-xl text-[#02B7D4] font-bold">
                            {
                              chartOrderData.filter(
                                (order) =>
                                  order?.tripId?.tripId ===
                                  chartOrderData[i]?.tripId?.tripId
                              ).length
                            }
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="font-bold text-sm text-[rgb(255,255,255,0.7)]">
                            Back to home Distance
                          </span>
                          <span className="text-xl text-[#02B7D4] font-bold">
                            {customDistanceFormat(
                              chartOrderData[i]?.tripId?.commands[
                                chartOrderData[i]?.tripId?.commands.length - 1
                              ].actionDistance
                            ) +
                              " " +
                              distanceUnit}
                          </span>
                        </div>
                        <div className="flex flex-col">
                          <span className="font-bold text-sm text-[rgb(255,255,255,0.7)]">
                            Back To Home Time
                          </span>
                          <span className="text-xl text-[#02B7D4] font-bold">
                            {customFormatTime(
                              (new Date(
                                chartOrderData[i]?.tripId?.commands.filter(
                                  (item) => item?.message === "MOVEHOME"
                                )[0]?.finishedAt
                              ).getTime() -
                                new Date(
                                  chartOrderData[i]?.tripId?.commands.filter(
                                    (item) => item?.message === "MOVEHOME"
                                  )[0].startedAt
                                ).getTime()) /
                                1000
                            )}
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
            </>
          )}
        </>
      );
    }

    return arr;
  };

  return (
    <>
      {renderRows()}
      {/* <div>
        <tr
          className={`${isActive && "bg-[#4B505F]"} rounded-t-md ${
            orderIndex !== chartOrderData.length - 1 &&
            !isActive &&
            "border-b border-[rgba(255,255,255,0.16)]"
          }`}
        >
          {chartOrderData[orderIndex + 1]?.tripId?.tripId ===
          order?.tripId?.tripId ? (
            <td className="p-3 rounded-tl-md" rowSpan={count}>
              {order?.tripId?.tripId}
            </td>
          ) : chartOrderData[orderIndex - 1]?.tripId?.tripId ===
            order?.tripId?.tripId ? (
            <td className="p-3 hidden">{order?.tripId?.tripId}</td>
          ) : (
            <td className="p-3 rounded-tl-md">{order?.tripId?.tripId}</td>
          )}
          <td className="p-3 rounded-tl-md">
            {fleetData?.robotsDetails?.find(
              (robot) => robot.robotId === order.robotId
            ).displayRobotName
              ? fleetData?.robotsDetails?.find(
                  (robot) => robot.robotId === order.robotId
                ).displayRobotName
              : order.robotId}
          </td>
          <td className="p-3">{order.orderId}</td>
          <td className="p-3 font-semibold capitalize">
            {orderStatusColor(order.orderStatus, order.statusCode)}
          </td>
          <td className="p-3">{order.cabinId}</td>
          {fleetData?.booking?.pickup &&
            fleetData?.booking?.pickup.length !== 0 && (
              <td className="p-3">
                {locationPositionValuePriority(order?.pickup)}
              </td>
            )}
          {fleetData?.booking?.delivery &&
            fleetData?.booking?.delivery.length !== 0 && (
              <td className="p-3">
                {locationPositionValuePriority(order?.delivery)}
              </td>
            )}
          <td className="p-3">
            {new Date(order.createdAt).toLocaleString("en-US", {
              timeZone: order?.timeZone ?? "America/New_York",
              timeZoneName: "short",
            })}{" "}
          </td>
          <td align="center" className="p-3 rounded-tr-md">
            {!isActive ? (
              <IoEyeOutline
                className="w-5 h-5 cursor-pointer text-[#00B7D4]"
                onClick={() => setIsActive(true)}
              />
            ) : (
              <IoCloseSharp
                className="w-5 h-5 cursor-pointer text-[#E96C67]"
                onClick={() => setIsActive(false)}
              />
            )}
          </td>
        </tr>
        {isActive && (
          <>
            <tr className="relative shadow-[inset_0px_0px_0px_12px_#4B505F] rounded-b-md">
              <td className="relative p-4 align-top">
                <div className="flex flex-col">
                  <span className="font-bold text-sm text-[rgb(255,255,255,0.7)]">
                    Total Time
                  </span>
                  <span className="text-xl text-[#02B7D4] font-bold">
                    {order.hasOwnProperty("orderTime")
                      ? customFormatTime(order?.orderTime) !== ""
                        ? customFormatTime(order?.orderTime)
                        : "0 sec"
                      : "0 sec"}{" "}
                  </span>
                </div>
              </td>
              <td
                colSpan={order.ETA ? 4 : 5}
                className="p-4 border-x border-x-[#4B505F] align-top"
              >
                <div className="flex items-start gap-2 justify-evenly">
                  {order?.pickupTransitTime ? (
                    <div className="flex flex-col">
                      <span className="font-bold text-sm text-[rgb(255,255,255,0.7)]">
                        Pickup Time
                      </span>
                      <span className="text-xl text-[#02B7D4] font-bold">
                        {order.hasOwnProperty("pickupTransitTime")
                          ? customFormatTime(order?.pickupTransitTime) !== ""
                            ? customFormatTime(order?.pickupTransitTime)
                            : "0 sec"
                          : "0 sec"}{" "}
                      </span>
                    </div>
                  ) : null}
                  {order?.pickupWaitTime ? (
                    <div className="flex flex-col">
                      <span className="font-bold text-sm text-[rgb(255,255,255,0.7)]">
                        Pickup Wait Time
                      </span>
                      <span className="text-xl text-[#02B7D4] font-bold">
                        {order.hasOwnProperty("pickupWaitTime")
                          ? customFormatTime(order?.pickupWaitTime) !== ""
                            ? customFormatTime(order?.pickupWaitTime)
                            : "0 sec"
                          : "0 sec"}{" "}
                      </span>
                    </div>
                  ) : null}
                  <div className="flex flex-col">
                    <span className="font-bold text-sm text-[rgb(255,255,255,0.7)]">
                      Delivery Time
                    </span>
                    <span className="text-xl text-[#02B7D4] font-bold">
                      {order.hasOwnProperty("dropoffTransitTime")
                        ? customFormatTime(order.dropoffTransitTime) !== ""
                          ? customFormatTime(order?.dropoffTransitTime)
                          : "0 sec"
                        : "0 sec"}{" "}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="font-bold text-sm text-[rgb(255,255,255,0.7)]">
                      Delivery Wait Time
                    </span>
                    <span className="text-xl text-[#02B7D4] font-bold">
                      {order.hasOwnProperty("dropoffWaitTime")
                        ? customFormatTime(order?.dropoffWaitTime) !== ""
                          ? customFormatTime(order?.dropoffWaitTime)
                          : "0 sec"
                        : "0 sec"}{" "}
                    </span>
                  </div>
                </div>
              </td>
              {order.ETA ? (
                <td className="p-4 border-r border-r-[#4B505F] ">
                  <div className="flex flex-col">
                    <span className="font-bold text-sm text-[rgb(255,255,255,0.7)]">
                      Estimated Delivery Time
                    </span>
                    <div className="flex items-center gap-1">
                      <span
                        className={`text-xl ${
                          order?.ETA > order?.orderTime
                            ? "text-[#71cf62]"
                            : "text-[#E96C67]"
                        } font-bold`}
                      >
                        {order.hasOwnProperty("ETA")
                          ? customFormatTime(order?.ETA) !== ""
                            ? customFormatTime(order?.ETA)
                            : "0 sec"
                          : "0 sec"}{" "}
                      </span>
                    </div>
                  </div>
                </td>
              ) : null}
              <td className="relative p-4 ">
                <div className="flex flex-col">
                  <span className="font-bold text-sm text-[rgb(255,255,255,0.7)]">
                    Total Distance
                  </span>
                  <span className="text-xl text-[#02B7D4] font-bold">
                    {calculateOrderDistance(order)}
                    {" " + distanceUnit}
                  </span>
                </div>
              </td>
            </tr>
            {order.hasOwnProperty("orderInfo") &&
              order.orderInfo.hasOwnProperty("analyticsdata") &&
              order.orderInfo.analyticsdata.hasOwnProperty("numberoforder") && (
                <td
                  colSpan={7}
                  className="p-4 border-x border-x-[#282f42] align-top "
                >
                  <div className="flex items-start gap-2 justify-evenly">
                    <div className="flex flex-col">
                      <span className="font-bold text-sm text-[rgb(255,255,255,0.7)]">
                        Total Orders
                      </span>
                      <span className="text-xl text-[#02B7D4] font-bold">
                        {order.hasOwnProperty("orderInfo") &&
                        order.orderInfo.hasOwnProperty("analyticsdata") &&
                        order.orderInfo.analyticsdata.hasOwnProperty(
                          "numberoforder"
                        )
                          ? order.orderInfo.analyticsdata.numberoforder
                          : "0"}{" "}
                      </span>
                    </div>
                    <div className="flex flex-col">
                      <span className="font-bold text-sm text-[rgb(255,255,255,0.7)]">
                        Total Bottles
                      </span>
                      <span className="text-xl text-[#02B7D4] font-bold">
                        {order.hasOwnProperty("orderInfo") &&
                        order.orderInfo.hasOwnProperty("analyticsdata") &&
                        order.orderInfo.analyticsdata.hasOwnProperty(
                          "numberofbottles"
                        )
                          ? order.orderInfo.analyticsdata.numberofbottles
                          : "0"}{" "}
                      </span>
                    </div>
                  </div>
                </td>
              )}
            <tr className="shadow-[inset_0px_0px_0px_12px_#4B505F] rounded-b-md !bg-[#35394a]">
              <td colSpan={3} className="relative p-4 align-middle  ">
                <div className="flex items-center justify-between">
                  <div className="flex flex-col">
                    <span className="font-bold text-xl text-[#BEC4DE] leading-[10px]">
                      25 min
                    </span>
                    <div className="flex items-center gap-1 text-sm font-light text-[#9C9EA6]">
                      <span className="h-1.5 w-5 bg-[#ECAE3F] "></span>
                      <span>Autonomous</span>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <span className="font-bold text-xl text-[#BEC4DE] leading-[10px]">
                      10 min
                    </span>
                    <div className="flex items-center gap-1 text-sm font-light text-[#9C9EA6]">
                      <span className="h-1.5 w-5 bg-[#EA515A] "></span>
                      <span>Teleoperated</span>
                    </div>
                  </div>
                </div>
              </td>
              <td colSpan={3} className="relative px-5 py-4 align-bottom">
                <span className="absolute w-[1px] h-full -top-3 left-0 bg-[#4B505F]"></span>
                <span className="absolute w-[1px] h-full -top-3 right-0 bg-[#4B505F]"></span>
                <div className="flex items-center justify-between">
                  <div className="flex flex-col">
                    <span className="font-bold text-xl text-[#BEC4DE] leading-[10px]">
                      0.4 miles
                    </span>
                    <div className="flex items-center gap-1 text-sm font-light text-[#9C9EA6]">
                      <span className="h-1.5 w-5 bg-[#ECAE3F] "></span>
                      <span>Autonomous</span>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <span className="font-bold text-xl text-[#BEC4DE] leading-[10px]">
                      0.1 miles
                    </span>
                    <div className="flex items-center gap-1 text-sm font-light text-[#9C9EA6]">
                      <span className="h-1.5 w-5 bg-[#EA515A] "></span>
                      <span>Teleoperated</span>
                    </div>
                  </div>
                </div>
              </td>
              <td colSpan={2} className="relative py-4 px-5 align-middle  ">
                <div className="flex flex-col">
                  <span className="font-bold text-xl text-[#BEC4DE] leading-[10px]">
                    5
                  </span>
                  <div className="flex items-center gap-1 text-sm font-light text-[#9C9EA6]">
                    <span className="h-1.5 w-5 bg-[#02B7D4] "></span>
                    <span>No. of Intervention</span>
                  </div>
                </div>
              </td>
            </tr>
          </>
        )}
      </div> */}
    </>
  );
};

export default CustomAccordion;
