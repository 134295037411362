import axios from "axios";

export async function login(values) {
  return axios
    .post(`${process.env.REACT_APP_BASE_URL}login/v1/login`, {
      emailId: values?.email,
      password: values?.password,
    })
    .then((res) => {
      return res;
    });
}
export const fleetlist = (email, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}fleet/v1/getFleetListAndRobotStatusByUserEmail`,
      {
        emailId: email,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const robotlist = (values, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}robot/v2/getRobotList`,
      {
        emailId: values?.email,
        password: values?.password,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const getrobotStatus = (robotid, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}robot/v1/getRobotStatus`,
      {
        robotId: robotid,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const RobotModeById = (robotId, token, runningState) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}interrupt/v1/setRobotModeById`,
      {
        robotId: robotId,
        robotMode: runningState,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const DisengageMotors = (robotId, token, State) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}interrupt/v1/setRobotModeById`,
      {
        robotId: robotId,
        robotMode: State,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const getLocationList = (fleetid, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}location/v1/locationListByFleetId`,
      {
        fleetId: fleetid,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const Openssh = (robotId, token, sessionState) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}interrupt/v1/setSshSessionbyRobotId`,
      {
        robotId: robotId,
        sessionStatus: sessionState,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const robotListByFleetId = (fleetId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}robot/v1/robotListByFleetId`,
      {
        fleetId: fleetId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const robotactionapi = (robotId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}robot/v1/getRobotCommandListByRobotId`,
      {
        robotId: robotId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const UpdateRobotAction = (
  robotId,
  token,
  status,
  actionId,
  orderId
) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}order/v1/completeAction`,
      {
        robotId: robotId,
        orderId: orderId,
        actionId: actionId,
        actionStatus: status,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const cancelOrderAPI = (id, fleetid, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}order/v1/cancelOrder`,
      {
        orderId: id,
        fleetId: fleetid,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const handleteleopAckinterrupt = (robotId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}interrupt/v1/teleopACK`,
      {
        robotId: robotId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const handleteleopComplete = (robotId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}interrupt/v1/teleopCompleted`,
      {
        robotId: robotId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const changeRobotWanderStatus = (robotId, token, wandermode) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}robot/v1/changeRobotWanderStatus`,
      {
        robotId: robotId,
        wanderStatus: "true",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const changeringlightStatus = (robotId, token, ringlight) => {
  if (ringlight) {
    return axios
      .post(
        `${process.env.REACT_APP_BASE_URL}interrupt/v1/sendHeadlightCommand`,
        {
          robotId: robotId,
          interruptAction: "DISABLE_RINGLIGHT",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        return res;
      });
  } else {
    return axios
      .post(
        `${process.env.REACT_APP_BASE_URL}interrupt/v1/sendHeadlightCommand`,
        {
          robotId: robotId,
          interruptAction: "ENABLE_RINGLIGHT",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        return res;
      });
  }
};
export const changeheadlightStatus = (robotId, token, headlight) => {
  if (headlight) {
    return axios
      .post(
        `${process.env.REACT_APP_BASE_URL}interrupt/v1/sendHeadlightCommand`,
        {
          robotId: robotId,
          interruptAction: "DISABLE_HEADLIGHT",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        return res;
      });
  } else {
    return axios
      .post(
        `${process.env.REACT_APP_BASE_URL}interrupt/v1/sendHeadlightCommand`,
        {
          robotId: robotId,
          interruptAction: "ENABLE_HEADLIGHT",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        return res;
      });
  }
};
export const sendhome = (robotId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}robot/v1/sendRobotHome`,
      {
        robotId: robotId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const resetDiagnostics = (robotId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}interrupt/v1/setRobotModeById`,
      {
        robotId: robotId,
        robotMode: "RESET_DIAGNOSTICS",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const opendoorone = (robotId, token, cabin) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}interrupt/v1/openDoorByRobotId`,
      {
        robotId: robotId,
        compartment: cabin,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const opendoortwo = (robotId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}interrupt/v1/openDoorByRobotId`,
      {
        robotId: robotId,
        compartment: "CABIN2",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const closedoorone = (robotId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}interrupt/v1/closeDoorByRobotId`,
      {
        robotId: robotId,
        compartment: "CABIN1",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const closedoortwo = (robotId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}interrupt/v1/closeDoorByRobotId`,
      {
        robotId: robotId,
        compartment: "CABIN2",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const EmergencyStop = (robotId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}interrupt/v1/emergencyStop`,
      {
        robotId: robotId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const ReleaseEmergencyStop = (robotId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}interrupt/v1/releaseEmergencyStop`,
      {
        robotId: robotId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const pauserobot = (robotId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}interrupt/v1/sendPauseCommand`,
      {
        robotId: robotId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const playrobot = (robotId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}interrupt/v1/sendRunCommand`,
      {
        robotId: robotId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const addfleet = (values, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}fleet/v2/addFleet`,
      {
        fleetName: values.fleetName,
        location: values.location,
        latitude: values.latitude,
        longitude: values.longitude,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const addRobot = (values, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}robot/v2/addRobot`,
      {
        fleetName: values.fleetName,
        location: values.location,
        latitude: values.latitude,
        longitude: values.longitude,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const addUser = (values, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}user/v2/addUser`,
      {
        emailId: values.emailId,
        password: values.password,
        firstName: values.firstName,
        lastName: values.lastName,
        contactNumber: values.contactNumber,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const userlist = (token) => {
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}user/v2/getUsersList`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      return res;
    });
};

export const editfleet = (values, token, id) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}fleet/v2/editFleet`,
      {
        fleetId: id,
        fleetName: values.fleetName,
        location: values.location,
        latitude: values.latitude,
        longitude: values.longitude,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const deletefleet = (id, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}fleet/v2/deleteFleet`,
      {
        fleetId: id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const editUser = (values, token, id) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}user/v2/editUser`,
      {
        userId: id,
        firstName: values.firstName,
        lastName: values.lastName,
        emailId: values.emailId,
        contactNumber: values.contactNumber,
        pin: values.pin,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const deleteuser = (id, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}user/v2/deleteUser`,
      {
        userId: id,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const getFleetListUser = (values, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}fleet/v1/getFleetListByUserEmail`,
      {
        emailId: values,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const restartvideo = (robotId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}interrupt/v1/setRobotModeById`,
      {
        robotId: robotId,
        robotMode: "RESTART_MULTI_STREAM_VIDEO",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const getorderstatus = (orderId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}order/status`,
      {
        orderId: orderId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const orderlist = (fleetId, page, size, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}order/list`,
      {
        fleetId: fleetId,
        pageNo: page,
        size: size,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const orderBook = (values, fleetId, token, fleetData) => {
  let body = {};
  let deliveryLocation = {};
  let pickupLocation = {};

  if (
    values.deliveryLocation === "Custom" &&
    fleetData.booking.delivery.length > 0
  ) {
    deliveryLocation.positionX = values.positionX;
    deliveryLocation.positionY = values.positionY;
    deliveryLocation.positionYaw = values.positionYaw;
    deliveryLocation.lat = values["delivery-lat"];
    deliveryLocation.lon = values["delivery-lon"];
  } else if (fleetData.booking.delivery.length > 0) {
    deliveryLocation = JSON.parse(values.deliveryLocation);
  }

  if (
    values.pickupLocation === "Custom" &&
    fleetData.booking.pickup.length > 0
  ) {
    pickupLocation.positionX = values.positionX;
    pickupLocation.positionY = values.positionY;
    pickupLocation.positionYaw = values.positionYaw;
    pickupLocation.lat = values["pickup-lat"];
    pickupLocation.lon = values["pickup-lon"];
  } else if (fleetData.booking.pickup.length > 0) {
    pickupLocation = JSON.parse(values.pickupLocation);
  }

  if (values.orderSize === "Custom") {
    body = {
      orderId: values.orderId,
      fleetId: fleetId,
      orderInfo: {
        dimension: {
          height: parseInt(values.height),
          length: parseInt(values.length),
          width: parseInt(values.width),
          // volume: parseInt(values.volume),
          weight: parseInt(values.weight),
        },
        // height: values.height,
        // length: values.length,
        // width: values.width,
        // weight: values.weight,
      },
      delivery:
        Object.keys(deliveryLocation).length > 0 ? deliveryLocation : null,
      pickup: Object.keys(pickupLocation).length > 0 ? pickupLocation : null,
      // scheduleStartTime: "2020-09-01 09:00:00",
      preparationTime: values.preparationTime,
      // phone: fleetData.booking.customerMobileNumber ? values.phone : null,
      orderType: values.orderType,
    };

    if (fleetData.booking.customerMobileNumber) {
      body.phone = values.phone;
    }
  } else if (values.orderSize === "Small") {
    body = {
      orderId: values.orderId,
      fleetId: fleetId,
      orderInfo: {
        dimension: {
          height: "10",
          length: "10",
          width: "10",
          // volume: parseInt(values.volume),
          weight: "10",
        },
        // height: values.height,
        // length: values.length,
        // width: values.width,
        // weight: values.weight,
      },
      delivery:
        Object.keys(deliveryLocation).length > 0 ? deliveryLocation : null,
      pickup: Object.keys(pickupLocation).length > 0 ? pickupLocation : null,
      // scheduleStartTime: "2020-09-01 09:00:00",
      preparationTime: values.preparationTime,
      // phone: fleetData.booking.customerMobileNumber ? values.phone : null,
      orderType: values.orderType,
    };

    if (fleetData.booking.customerMobileNumber) {
      body.phone = values.phone;
    }
  } else if (values.orderSize === "Large") {
    body = {
      orderId: values.orderId,
      fleetId: fleetId,
      orderInfo: {
        dimension: {
          height: "37",
          length: "20",
          width: "20",
          // volume: parseInt(values.volume),
          weight: "20",
        },
        // height: values.height,
        // length: values.length,
        // width: values.width,
        // weight: values.weight,
      },
      delivery:
        Object.keys(deliveryLocation).length > 0 ? deliveryLocation : null,
      pickup: Object.keys(pickupLocation).length > 0 ? pickupLocation : null,
      // scheduleStartTime: "2020-09-01 09:00:00",
      preparationTime: values.preparationTime,
      // phone: fleetData.booking.customerMobileNumber ? values.phone : null,
      orderType: values.orderType,
    };

    if (fleetData.booking.customerMobileNumber) {
      body.phone = values.phone;
    }
  } else {
    let orderSize = JSON.parse(values.orderSize);

    body = {
      orderId: values.orderId,
      fleetId: fleetId,
      orderInfo: {
        dimension: {
          height: parseInt(orderSize.height),
          length: parseInt(orderSize.length),
          width: parseInt(orderSize.width),
          // volume: parseInt(orderSize.volume),
          weight: parseInt(orderSize.weight),
        },
        // height: orderSize.height,
        // length: orderSize.length,
        // width: orderSize.width,
        // weight: orderSize.weight,
      },
      autoLoad: values.autoLoad,
      robotId: values.robotId,
      orderType: values.orderType,
      delivery:
        Object.keys(deliveryLocation).length > 0 ? deliveryLocation : null,
      pickup: Object.keys(pickupLocation).length > 0 ? pickupLocation : null,
      // scheduleStartTime: "2020-09-01 09:00:00",
      preparationTime: values.preparationTime,
      // phone: fleetData.booking.customerMobileNumber ? values.phone : null,
    };

    if (fleetData.booking.customerMobileNumber) {
      body.phone = values.phone;
    }
  }

  if (fleetData?.booking?.preparationTime) {
    if (values.alreadyPrepared) {
      delete body["preparationTime"];
      body.preparationStatusCode = 1;
    } else {
      body.preparationStatusCode = 0;
    }
  }

  return axios
    .post(`${process.env.REACT_APP_BASE_URL}order/booking`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      return res;
    });
};

export const orderListFleetId = (values, page, size, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}order/v1/orderListByFleetIdPagination`,
      {
        fleetId: values,
        pageNo: page,
        size: size,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const addorder = (values, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}order/booking`,
      {
        orderId: values.orderId,
        orderInfo: {
          size: values.orderInfoSize,
          dimension: {
            height: parseInt(values.height),
            length: parseInt(values.length),
            width: parseInt(values.width),
            volume: parseInt(values.volume),
            weight: parseInt(values.weight),
          },
        },
        pickup: {
          lon: parseFloat(values.pickuplon),
          lat: parseFloat(values.pickuplat),
        },
        delivery: {
          lon: parseFloat(values.deliverylon),
          lat: parseFloat(values.deliverylat),
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const orderConfirm = (OrderId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}order/confirm`,
      {
        orderId: OrderId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const sendOtpmail = (emailId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}users/sendOtpOnMail`,
      {
        emailId: emailId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const verifyOtpMail = (emailId, otp, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}users/verifyOtp`,
      {
        emailId: emailId,
        otp: otp,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const changepassword = (emailId, newPassword, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}users/forgetPassword`,
      {
        emailId: emailId,
        newPassword: newPassword,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const cancelActionAPI = (orderId, fleetid, actionId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}order/v1/cancelAction`,
      {
        orderId: orderId,
        fleetId: fleetid,
        actionId: actionId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const MapDetails = (fleetid, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}map/v1/getMapDetailByfleetId`,
      {
        fleetId: fleetid,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const robotListfleetId = (values, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}robot/v1/getRobotStatusByFleetId`,
      {
        fleetId: values,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const getMapLocationList = (fleetid, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}location/v1/locationListByFleetId`,
      {
        fleetId: fleetid,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const getrobotpathMain = (robotId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}robot/v2/getRobotPath`,
      {
        robotId: robotId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const getgeofenceZone = (fleetId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}robot/v2/getFleetGeofence`,
      {
        fleetId: fleetId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const getfleetzones = (fleetId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}robot/v2/getFleetZones`,
      {
        fleetId: fleetId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const getnogozones = (fleetId, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}robot/v2/getFleetNogoZones`,
      {
        fleetId: fleetId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

// export const askimageurl=(token)=>{
//   return axios.post(`${process.env.REACT_APP_BASE_URL}map/askuploadurl`,{} ,{
//      headers:{
//          'Authorization':`Bearer ${token}`,
//          'Content-Type': 'application/json',
//      }
//   }).then((res)=>{
//      return res
//  })
// }
export const imageupload = (token, bodyFormData) => {
  // console.log('bodyFormData',bodyFormData)
  return axios
    .post(`${process.env.REACT_APP_BASE_URL}image/upload`, bodyFormData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      return res;
    });
};

export const imageuploadv2 = (token, bodyFormData) => {
  console.log("bodyFormData", bodyFormData);
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}v2/uploadImage`,
      {
        uploadedFileBlob: bodyFormData,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const askuploadtype = (filetype, token) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}v2/askuploadurl`,
      {
        type: filetype,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const updateimagetoCdn = (fileurl, filepath, token) => {
  return axios
    .post(fileurl, filepath, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": `multipart/form-data`,
      },
    })
    .then((res) => {
      return res;
    });
};

export const addMap = (data, fleetId, token, imagepath) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}map/v1/addMap`,
      {
        mapName: data.mapName,
        fleetId: fleetId,
        mapOrigionX: data.mapOriginY,
        mapOrigionY: data.mapOriginX,
        mapScale: "",
        mapHeight: data.mapHeight,
        mapWidth: data.mapWidth,
        baseMapImageUrl: "",
        floorplanImageUrl: "",
        noGoZoneImageUrl: "",
        annotationImage: "",
        mapStatus: "true",
        imageUrl: imagepath,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const editMap = (data, fleetId, token, imagepath, mapId) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}map/v1/editMap`,
      {
        mapId: mapId,
        mapName: data.mapName,
        fleetId: fleetId,
        mapOrigionX: data.mapOriginY,
        mapOrigionY: data.mapOriginX,
        mapScale: "",
        mapHeight: data.mapHeight,
        mapWidth: data.mapWidth,
        baseMapImageUrl: "",
        floorplanImageUrl: "",
        noGoZoneImageUrl: "",
        annotationImage: "",
        mapStatus: "true",
        imageUrl: imagepath,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const deleteMap = (mapId, token) => {
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}map/v1/deleteMap`,
    {
      mapId: mapId,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );
};
export const getmaplist = (token) => {
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}map/v1/getMapList`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      return res;
    });
};
export const wandermode = (robotId, status, token) => {
  return axios
    .post(
      `http://52.242.78.13:5000/robot/v1/sendRobotWander`,
      {
        robotId: robotId,
        status: status,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const addwaypoint = (
  fleetId,
  robotId,
  locationName,
  lat,
  lon,
  token
) => {
  return axios
    .post(
      `http://52.242.78.13:5000/v2/addLocation`,
      {
        fleetId: fleetId,
        homeRobot: robotId,
        locationName: locationName,
        locationType: "WANDER", // WANDER
        lat: lat,
        lon: lon,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const editwaypoint = (
  fleetId,
  robotId,
  locationName,
  locationId,
  lat,
  lon,
  token
) => {
  return axios
    .post(
      `http://52.242.78.13:5000/v2/editLocation`,
      {
        fleetId: fleetId,
        homeRobot: robotId,
        locationId: locationId,
        locationName: locationName,
        locationType: "WANDER", // WANDER
        lat: lat,
        lon: lon,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
    .then((res) => {
      return res;
    });
};
export const waypointslist = (robotId, token) => {
  return axios
    .post(
      `http://52.242.78.13:5000/v2/getlocation`,
      {
        robotId: robotId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const getLocationListLive = (fleetId) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}v2/getLocationListLive`,
      {
        fleetId: fleetId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const getLocationListByType = (fleetId) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}v2/getLocationsByLocationType`,
      {
        fleetId: fleetId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const updateLocationProperty = (
  locationId,
  locationProperty,
  locationStatusValue
) => {
  return axios
    .put(
      `${process.env.REACT_APP_BASE_URL}v2/editLocation/${locationId}`,
      {
        [locationProperty]: locationStatusValue,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const addLocation = (fleetId, locationValues) => {
  let body =
    locationValues.imageLocationUrl !== ""
      ? {
          locationType: locationValues.locationType,
          locationName: locationValues.locationName,
          fleetId: fleetId,
          positionX: locationValues.positionX,
          positionY: locationValues.positionY,
          positionYaw: locationValues.positionYaw,
          latitude: locationValues.latitude,
          longitude: locationValues.longitude,
          headingAngle: locationValues.headingAngle,
          status: locationValues.status,
          imageLocationUrl: locationValues.imageLocationUrl,
          group: locationValues.group,
        }
      : {
          locationType: locationValues.locationType,
          locationName: locationValues.locationName,
          fleetId: fleetId,
          positionX: locationValues.positionX,
          positionY: locationValues.positionY,
          positionYaw: locationValues.positionYaw,
          latitude: locationValues.latitude,
          longitude: locationValues.longitude,
          headingAngle: locationValues.headingAngle,
          status: locationValues.status,
          group: locationValues.group,
        };

  return axios
    .post(`${process.env.REACT_APP_BASE_URL}v2/addLocation`, body, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      return res;
    });
};

export const editLocation = (locationId, locationValues) => {
  let body =
    locationValues.imageLocationUrl !== ""
      ? {
          locationName: locationValues.locationName,
          locationType: locationValues.locationType,
          latitude: locationValues.latitude,
          longitude: locationValues.longitude,
          headingAngle: locationValues.headingAngle,
          positionX: locationValues.positionX,
          positionY: locationValues.positionY,
          positionYaw: locationValues.positionYaw,
          status: locationValues.status,
          imageLocationUrl: locationValues.imageLocationUrl,
          group: locationValues.group,
        }
      : {
          locationName: locationValues.locationName,
          locationType: locationValues.locationType,
          latitude: locationValues.latitude,
          longitude: locationValues.longitude,
          headingAngle: locationValues.headingAngle,
          positionX: locationValues.positionX,
          positionY: locationValues.positionY,
          positionYaw: locationValues.positionYaw,
          status: locationValues.status,
          imageLocationUrl: "",
          group: locationValues.group,
        };

  return axios
    .put(
      `${process.env.REACT_APP_BASE_URL}v2/editLocation/${locationId}`,
      body,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res;
    });
};

export const deleteLocation = (location) => {
  let body =
    location.imageLocationUrl && location.imageLocationUrl !== ""
      ? {
          data: {
            locationId: location._id,
            imageId: location?.imageLocationUrl?.split("/")[4],
          },
        }
      : {
          data: {
            locationId: location._id,
          },
        };

  return axios
    .delete(`${process.env.REACT_APP_BASE_URL}v2/deleteLocation`, body, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      return res;
    });
};

export const changeLocation = (
  orderId,
  selectedLocation,
  token,
  locationType
) => {
  const locationValue = JSON.parse(selectedLocation.value);
  let body = {};
  if (locationType === "pickup") {
    body = {
      orderId: orderId,
      pickup: {
        locationName: selectedLocation.label,
        positionX: locationValue.positionX,
        positionY: locationValue.positionY,
        positionYaw: locationValue.positionYaw,
        headingAngle: locationValue.headingAngle,
        latitude: locationValue.latitude,
        longitude: locationValue.longitude,
      },
    };
  } else {
    body = {
      orderId: orderId,
      delivery: {
        locationName: selectedLocation.label,
        positionX: locationValue.positionX,
        positionY: locationValue.positionY,
        positionYaw: locationValue.positionYaw,
        headingAngle: locationValue.headingAngle,
        latitude: locationValue.latitude,
        longitude: locationValue.longitude,
      },
    };
  }
  return axios
    .post(`${process.env.REACT_APP_BASE_URL}order/change-location`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      return res;
    });
};
